import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Language } from '@material-ui/icons';

import logoDesktop from '../../assets/o2fs_desktop_logo.png';
import { LinkButton } from '../navigation';
/* import MenuConfiguration from '../../menu/cz/menuConfiguration'; */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      /* background: 'linear-gradient(to bottom, #000033, #5AA0D1)', */
      background: 'linear-gradient(180deg, #24246a 0, #107ac0 100%)',
      flexShrink: 0,
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(0),
      },
    },
    container: {
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
      },
    },
    grid: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    link: {
      color: theme.palette.common.white,
      textDecoration: 'underline',
      '&:hover': { textDecoration: 'none' },
    },
    menuItem: {
      color: theme.palette.common.white,
    },
    menuItemText: {
      color: theme.palette.common.white,
      fontSize: '18px',
    },
    menuItemDivider: {
      backgroundColor: fade(theme.palette.common.white, 0.4),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    text: {
      color: theme.palette.common.white,
    },
    logo: {
      height: 'fit-content',
    },
    gridItemWithPadding: {
      padding: theme.spacing(2.5),
    },
    gridItemWithoutPadding: {
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.5),
    },
    icon: {
      color: theme.palette.common.white,
      paddingRight: theme.spacing(1),
    },
  })
);

const FooterComponent = (): React.ReactElement => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesUplg = useMediaQuery(theme.breakpoints.up('lg'));
  const logoDesktopComponent = <img src={logoDesktop} alt="logo" className={classes.logo} />;
  const textAlign = matchesUplg ? 'left' : 'center';
  const gridJustify = matchesUplg ? 'flex-start' : 'center';
  const maxWidth = matchesUplg ? 'lg' : 'md';
  /* const menuItemToShowInFooter = MenuConfiguration.filter((menuConfigurationItem) => menuConfigurationItem.showInFooter); */

  return (
    <footer className={classes.root}>
      <Container maxWidth={maxWidth} className={classes.container}>
        <Grid container className={classes.grid} justify={gridJustify} /* spacing={5} */>
          <Grid item xs={12} md={6} container justify={gridJustify} className={classes.gridItemWithPadding}>
            {logoDesktopComponent}
          </Grid>
          <Grid item xs={12} md={6} container justify={gridJustify} className={classes.gridItemWithPadding}>
            {/*  {menuItemToShowInFooter.map((menuConfigurationItem, index) => (
              <React.Fragment key={menuConfigurationItem.title}>
                <menuConfigurationItem.component
                  route={menuConfigurationItem.route}
                  isExternal={menuConfigurationItem.isExternal}
                  classes={{ root: classes.menuItem, text: classes.menuItemText }}
                  text={menuConfigurationItem.title}
                />
                {index < menuItemToShowInFooter.length - 1 ? (
                  <Divider className={classes.menuItemDivider} orientation="vertical" flexItem />
                ) : undefined}
              </React.Fragment>
            ))} */}
          </Grid>
          <Grid container item xs={12} md={6}>
            <Grid item xs={12} className={classes.gridItemWithPadding}>
              <Typography className={classes.text} align={textAlign}>
                ID No 05423716, VAT No CZ 05423716
              </Typography>
              <Typography className={classes.text} align={textAlign}>
                Praha 4, Michle, Za Brumlovkou 266/2, PSČ 14022
              </Typography>
              <Typography className={classes.text} align={textAlign}>
                Registered in the Commercial Register kept at the Registration Court in Prague, Section C, Insert 262760
              </Typography>
              <Typography className={classes.text} align={textAlign}>
                100% subsidiary of O2 Czech Republic a.s. and a member of O2 Czech Republic holding
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6} direction="column" className={classes.gridItemWithPadding}>
            <Grid container justify={gridJustify}>
              <Language classes={{ root: classes.icon }} />
              <LinkButton
                route="external/cz"
                isExternal
                classes={{ root: classes.link, text: classes.text }}
                textAlign={textAlign}
                text="www.o2fs.cz"
                textVariant="subtitle1"
              />
            </Grid>
            <Grid container justify={gridJustify}>
              <Language classes={{ root: classes.icon }} />
              <LinkButton
                route="external/sk"
                isExternal
                classes={{ root: classes.link, text: classes.text }}
                textAlign={textAlign}
                text="www.o2fs.sk"
                textVariant="subtitle1"
              />
            </Grid>
            <Grid container justify={gridJustify}>
              <Language classes={{ root: classes.icon }} />
              <LinkButton
                route="external/hu"
                isExternal
                classes={{ root: classes.link, text: classes.text }}
                textAlign={textAlign}
                text="www.o2fs.hu"
                textVariant="subtitle1"
              />
            </Grid>
            <Grid container justify={gridJustify}>
              <LinkButton
                route="external/cz/whistleblowing"
                isExternal
                classes={{ root: classes.link, text: classes.text }}
                textAlign={textAlign}
                text="Whistleblowing"
                textVariant="subtitle1"
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default FooterComponent;
