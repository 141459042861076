import React from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

import { ROUTES } from '../navigation';
import logoDesktop from '../../assets/o2fs_desktop_logo.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      zIndex: theme.zIndex.drawer + 1,
    },
    logoDesktop: { verticalAlign: 'middle' },
    logoMobile: { verticalAlign: 'middle' },
    toolbar: {
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      paddingLeft: theme.spacing(3.75),
      paddingRight: theme.spacing(0),
    },
  })
);

const HeaderComponent = React.memo((): React.ReactElement => {
  const classes = useStyles();

  const logoComponent = (
    <Link to={ROUTES.home}>
      <img src={logoDesktop} alt="logo" className={classes.logoDesktop} />
    </Link>
  );

  return (
    <header>
      <AppBar position="fixed" className={clsx(classes.appBar, {})}>
        <Toolbar classes={{ root: classes.toolbar }}>
          <Grid container alignItems="center">
            <Grid item xs={9} container alignItems="center">
              {logoComponent}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </header>
  );
});

export default HeaderComponent;
