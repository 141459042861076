import React, { Fragment } from 'react';
import { Route, RouteComponentProps, RouteProps, withRouter } from 'react-router-dom';

class ScrollToTopRoute extends React.Component<RouteProps & RouteComponentProps> {
  componentDidMount(): void {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps: RouteProps): void {
    if (
      this.props.location &&
      prevProps.location &&
      this.props.path === this.props.location.pathname &&
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      window.scrollTo(0, 0);
    }
  }

  render(): React.ReactElement {
    const { component: Component, render, ...rest }: RouteProps = this.props;

    if (render) {
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      return <Route {...rest} render={render} />;
    }
    
    if (!Component) {
      return <Fragment />;
    }

    /* eslint-disable-next-line react/jsx-props-no-spreading */
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
}

export default withRouter(ScrollToTopRoute);
