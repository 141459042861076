import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ROUTES } from '../navigation';
import { ScrollToTopRoute } from '../../components/scrollToTopRoute';

import HomePage from '../homePage';
import SupportedBrowsers from '../supportedBrowsers';
/* import Cookies from '../cookies'; */

const AppRouterComponent = (): React.ReactElement => {
  return (
    <Switch>
      <ScrollToTopRoute exact path={ROUTES.root} component={HomePage} />
      {/* <ScrollToTopRoute exact path={ROUTES.cookies} component={Cookies} /> */}
      <ScrollToTopRoute exact path={ROUTES.supportedBrowser} component={SupportedBrowsers} />
      <Route>
        <Redirect to={ROUTES.root} />
      </Route>
    </Switch>
  );
};

export default AppRouterComponent;
