import { ReportHandler } from 'web-vitals';

const reportWebVitals = (onPerfEntry?: ReportHandler): void => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals')
      .then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
        getCLS(onPerfEntry);
        getFID(onPerfEntry);
        getLCP(onPerfEntry);

        /* getTTFB(onPerfEntry);
        getFCP(onPerfEntry); */

        return true;
      })
      .catch((error: Error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }
};

export default reportWebVitals;
