import React from 'react';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import { PrimaryButton } from '../button';

interface IBigTileComponentProps {
  imageProviderSrc: string;
  imageLanguageSrc: string;
  firstLineText: string;
  secondLineText: string;
  languageDescription: string;
  titleButton: string;
  onClick?: () => void;
  tabIndex?: number;
}

const useBigTileStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '315px',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '380px',
      },
      /* height: '100%', */
      /* padding: theme.spacing(1),
      paddingBottom: '0px', */
      backgroundColor: theme.palette.common.white,
      /* '&:hover': { color: theme.palette.primary.main, opacity: 0.6, cursor: 'pointer' }, */
      /*  '&:focus': { outline: 'none' }, */
    },
    container: {
      /* height: '100%', */
      padding: theme.spacing(0),
    },
    content: {
      padding: '16px 16px 12px 16px',
      [theme.breakpoints.up('sm')]: {
        padding: 'unset',
      },
    },
    footer: {
      padding: theme.spacing(1.5),
    },
    image: {
      display: 'block',
      /* maxHeight: '180px', */
      /*  maxWidth: '100%', */
    },
    grid: {
      /* height: '100%' */
    },
    title: {
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: '15px',
      lineHeight: '22px',
      /* '&:hover': { opacity: 1 }, */
    },
    languageDescription: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '18px',
      lineHeight: '27px',
    },
    horizontalLine: {
      width: '100%',
      border: '1px #D8D8D8 solid',
    },
    buttonContainer: {
      /*  '&.MuiGrid-item': { paddingTop: '0px' },
      paddingBottom: theme.spacing(3.75), */
    },
    /*  link: {
      textDecoration: 'none',
      color: 'inherit',
    }, */
  })
);

const BigTileComponent = (props: IBigTileComponentProps): React.ReactElement => {
  const classes = useBigTileStyles();
  const theme = useTheme();

  const onTileClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    if (props.onClick) {
      event.preventDefault();
      props.onClick();
    }
  };

  return (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */
    <div className={classes.root} onClick={onTileClick} role="button" tabIndex={props.tabIndex}>
      <Container maxWidth="xs" className={classes.container}>
        <Grid container direction="row" alignContent="space-between" className={classes.grid}>
          {/* <Hidden xsDown> */}
          <Grid item xs={12} container justify="center">
            <Box mt={5} mb={3.25}>
              <img src={props.imageProviderSrc} alt={props.languageDescription} className={classes.image} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="h6" className={classes.title}>
              {props.firstLineText}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="h6" className={classes.title}>
              {props.secondLineText}
            </Typography>
          </Grid>
          {/*   </Hidden> */}
          {/* <Hidden smUp>
            <Grid item xs={12} container justify="center" className={classes.content}>
              <Grid item xs={3} container justify="flex-start" alignItems="center">
                <Box>
                  <img src={props.imageProviderSrc} alt={props.languageDescription} className={classes.image} />
                </Box>
              </Grid>
              <Grid item xs={9} container justify="flex-start" alignItems="center">
                <Box pl={3}>
                  <Typography align="left" variant="h6" className={classes.title}>
                    {props.firstLineText}
                  </Typography>

                  <Typography align="left" variant="h6" className={classes.title}>
                    {props.secondLineText}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Hidden> */}
          <Grid item xs={12} container>
            <Box mt={1.25} className={classes.horizontalLine} />
          </Grid>
          <Grid item xs={12} container justify="center" alignItems="center" className={classes.footer}>
            <Grid item xs={6} container justify="flex-start" alignItems="center">
              <Box>
                <img src={props.imageLanguageSrc} alt={props.titleButton} className={classes.image} />
              </Box>
              <Box pl={2}>
                <Typography align="center" variant="h6" className={classes.languageDescription}>
                  {props.languageDescription}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} container justify="flex-end" classes={{ item: classes.buttonContainer }}>
              <PrimaryButton fullWidth>{props.titleButton}</PrimaryButton>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

BigTileComponent.defaultProps = {
  tabIndex: -1,
  onClick: undefined,
};

export default BigTileComponent;
