import React, { useCallback } from 'react';
import MuiLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

import { IBaseLinkProps } from './types';
import ROUTES from './routes';

const LinkButtonComponent = (props: IBaseLinkProps): React.ReactElement<IBaseLinkProps> => {
  const { text, link, ...muiLinkClasses } = props.classes;
  const onClickHandler = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {      
      if (props.onClick) {
        props.onClick();
      }
    },
    [props.onClick]
  );

  return props.isExternal ? (
    <a href={ROUTES[props.route]} target="_blank" rel="noopener noreferrer" className={link}>
      <MuiLink key={props.text} classes={muiLinkClasses} component="button" variant={props.textVariant}>
        <Typography className={text} align={props.textAlign}>
          {props.text}
        </Typography>
      </MuiLink>
    </a>
  ) : (
    <Link to={ROUTES[props.route]} className={link}>
      <MuiLink key={props.text} classes={muiLinkClasses} component="button" variant={props.textVariant}>
        <Typography className={text} align={props.textAlign} /* onClick={onClickHandler} */>
          {props.text}
        </Typography>
      </MuiLink>
    </Link>
  );
};

export default LinkButtonComponent;
