import React from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';

import AppRouter from '../router/appRouter';
import headerGradient from '../../assets/headerGradient.jpg';
import Footer from './footer';

const useLayoutStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1, 
      minHeight: '100vh',
      flexDirection: 'column',
      backgroundImage: `url(${headerGradient})`,
      backgroundSize: 'auto 100%',
      backgroundRepeat: 'repeat',
    },
  })
);

const LayoutComponent = (): React.ReactElement => {
  const classes = useLayoutStyles();

  return (
    <Box className={classes.root}>
      <AppRouter />
      <Footer />
    </Box>
  );
};

export default LayoutComponent;
