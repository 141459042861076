import React from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

const useSupportedBrowsersStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#fafafa',
      flex: 1,
      width: '100%',
      paddingTop: '44px',
    },
    h1: {
      paddingBottom: 8,
    },
    h2: {
      paddingBottom: 8,
    },
    h3: {
      paddingBottom: 8,
    },
    body1: {
      paddingBottom: 8,
    },
    link: { textDecoration: 'underline' },
    text: {},
    table: {
      border: '1px solid #cecece',
    },
    tableCell: { border: '1px solid #cecece' },
  })
);

const HeaderTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#f2f2f2',
      color: theme.palette.common.black,
      border: '1px solid #cecece',
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

function createData(browserName: string, supported: string): { browserName: string; supported: string; key: string } {
  return { browserName, supported, key: browserName + supported };
}

const rows = [
  createData('Chrome', '>= 49'),
  createData('Firefox', '>= 52'),
  createData('Edge', '> 79'),
  createData('Opera', '>=74.0.3911.160'),
  createData('Safari', '>= 10'),
];

const SupportedBrowsersComponent = (): React.ReactElement => {
  const classes = useSupportedBrowsersStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <Container maxWidth="lg">
        <Typography variant="h1">{t('supportedBrowsers:title')}</Typography>
        <Box pb={2.5} />
        <Typography variant="body1">{t('supportedBrowsers:body')}</Typography>
        <Box pb={1.25} />
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <HeaderTableCell>{t('supportedBrowsers:tableColumns:browser')}</HeaderTableCell>
                <HeaderTableCell>{t('supportedBrowsers:tableColumns:supportedVersion')}</HeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={row.key}>
                  <TableCell scope="row" className={classes.tableCell}>
                    {row.browserName}
                  </TableCell>
                  <TableCell className={classes.tableCell}>{row.supported}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
};

export default SupportedBrowsersComponent;
