import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import { ROUTES } from '../navigation';
import { BigTile } from '../../components/tiles';
import Header from '../layout/header';
import headerGradient from '../../assets/headerGradient.jpg';
import czFlag from '../../assets/cz_flag.png';
import huFlag from '../../assets/hu_flag.png';
import skFlag from '../../assets/sk_flag.png';
import yettelLogo from '../../assets/yettel_logo.png';
import o2Logo from '../../assets/o2_logo.png';
import o2LogoSmall from '../../assets/o2_logo_sm.png';

const useHomePageStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { backgroundImage: `url(${headerGradient})`, backgroundSize: 'auto 100%', backgroundRepeat: 'repeat' },
    headerWithBanner: {
      zIndex: 1,
      flex: 1,
      height: '550px',
      textAlign: 'center',
      padding: '70px 16px 32px 16px',
      [theme.breakpoints.up('sm')]: {
        paddingTop: '100px',
        paddingBottom: '80px',
      },
    },
    link: {
      textDecoration: 'none',
      color: 'inherit',
      maxWidth: '315px',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '380px',
      },
    },
    pageTitle: {
      color: theme.palette.common.white,
      fontSize: theme.spacing(4),
      lineHeight: `${theme.spacing(5)}px`,
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.spacing(5),
        lineHeight: `${theme.spacing(6)}px`,
      },
    },
  })
);

const HomePageComponent = (): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useHomePageStyles();
  const theme = useTheme();
  const matchesUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const boxPb = matchesUpSm ? 14.875 : 0;
  const boxUnderPageTitlePb = matchesUpSm ? 14.875 : 3.75;
  const o2LogoByMediaQuery = matchesUpSm ? o2Logo : o2LogoSmall;
  const telenorLogoByMediaQuery = yettelLogo;
  const skLogoByMediaQuery = matchesUpSm ? o2Logo : o2LogoSmall;

  return (
    <React.Fragment>
      <Header />
      <div className={classes.headerWithBanner}>
        {/* <Box pb={boxPb} /> */}
        <Typography className={classes.pageTitle}>Select your country and service</Typography>
        <Box pb={boxUnderPageTitlePb} />
        <Grid container direction="row" justify="center" alignItems="center" spacing={4}>
          <Grid item container xs={12} sm={6} lg={4} justify="center">
            <a href={ROUTES['external/cz']} className={classes.link} target="_self" rel="noopener noreferrer">
              <BigTile
                imageProviderSrc={o2LogoByMediaQuery}
                imageLanguageSrc={czFlag}
                firstLineText={t('homePage:tiles:cz:firstLine')}
                secondLineText={t('homePage:tiles:cz:secondLine')}
                languageDescription={t('homePage:tiles:cz:languageDescription')}
                titleButton={t('homePage:tiles:cz:titleButton')}
                /* onClick={onNavigateToCzWebClick} */
              />
            </a>
          </Grid>
          <Grid item container xs={12} sm={6} lg={4} justify="center">
            <a href={ROUTES['external/sk']} className={classes.link} target="_self" rel="noopener noreferrer">
              <BigTile
                imageProviderSrc={skLogoByMediaQuery}
                imageLanguageSrc={skFlag}
                firstLineText={t('homePage:tiles:sk:firstLine')}
                secondLineText={t('homePage:tiles:sk:secondLine')}
                languageDescription={t('homePage:tiles:sk:languageDescription')}
                titleButton={t('homePage:tiles:sk:titleButton')}
                /* onClick={onNavigateToHuWebClick} */
              />
            </a>
          </Grid>
          <Grid item container xs={12} sm={6} lg={4} justify="center">
            <a href={ROUTES['external/hu']} className={classes.link} target="_self" rel="noopener noreferrer">
              <BigTile
                imageProviderSrc={telenorLogoByMediaQuery}
                imageLanguageSrc={huFlag}
                firstLineText={t('homePage:tiles:hu:firstLine')}
                secondLineText={t('homePage:tiles:hu:secondLine')}
                languageDescription={t('homePage:tiles:hu:languageDescription')}
                titleButton={t('homePage:tiles:hu:titleButton')}
                /* onClick={onNavigateToHuWebClick} */
              />
            </a>
          </Grid>
        </Grid>
      </div>
      {/* <div className={classes.footer} /> */}
    </React.Fragment>
  );
};

export default HomePageComponent;
