import configuration from '../../configuration/url';

const ROUTES = {
  root: '/',
  home: '/home',
  cookies: '/cookies',
  supportedBrowser: '/supported-browser',

  'external/cz': configuration.czWebUrl,
  'external/hu': configuration.huWebUrl,
  'external/sk': configuration.skWebUrl,

  'external/cz/whistleblowing': configuration.czWebWhistleblowingUrl,
};

export default ROUTES;
