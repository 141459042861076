import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '36px',
      borderRadius: '2px',
      textTransform: 'none',
      maxWidth: '90px',
    },
    secondary: {
      color: theme.palette.common.white,
    },
  })
);

export default useButtonStyles;
