import { createReducer } from '@reduxjs/toolkit';
import produce from 'immer';

import { routeChangeAction } from './actions';
import { INavigationState } from './types.state';

const initialState: INavigationState = {
  currentRoute: '',
};

const reducer = createReducer(initialState, (builder) =>
  builder.addCase(routeChangeAction, (state, action) => {
    const nextState = produce(state, (draftState) => {
      draftState.currentRoute = action.payload;
    });

    return nextState;
  })
);

export default reducer;
