import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';

import useButtonStyles from './useButtonStyles';

const PrimaryButtonComponent = (props: ButtonProps): React.ReactElement => {
  const classes = useButtonStyles();
  const { variant, color, className, ...buttonProps } = props;

  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <Button variant="contained" color="primary" className={classes.root} {...buttonProps}>
      {props.children}
    </Button>
  );
};

export default PrimaryButtonComponent;
