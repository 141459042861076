import React from 'react';

import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';

import Layout from '../layout';

function App(): React.ReactElement {
  return (    
      <Layout />
  );
}

export default App;
