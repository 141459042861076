import { createMuiTheme } from '@material-ui/core/styles';

const themeWithBreakpoints = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 767,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: '#0088CC',
      light: '#88CCFF',
    },
    secondary: {
      main: '#99CC00',
    },
    error: {
      main: '#F03246',
    },
    background: {
      default: '#F2F2F2',
      paper: '#fff',
    },
    online: {
      dark: '#7ca600',
      main: '#F5FFD9',
    },
  },
});

const theme = createMuiTheme({
  breakpoints: themeWithBreakpoints.breakpoints,
  palette: themeWithBreakpoints.palette,
  typography: {
    fontFamily: '"Open Sans"',
    h1: {
      fontSize: 40,
    },
    h2: {
      fontSize: 26,
    },
    h3: {
      fontSize: 18,
      fontWeight: 600,
    },
  },
  mixins: {
    toolbar: {
      minHeight: themeWithBreakpoints.spacing(8.75),
      [themeWithBreakpoints.breakpoints.up('sm')]: {
        minHeight: themeWithBreakpoints.spacing(12.5),
      },
    },
  },
  overrides: {
    MuiInputLabel: {},
    MuiContainer: {
      maxWidthLg: {
        paddingLeft: 'unset',
        paddingRight: 'unset',
        maxWidth: '1200px',
        [themeWithBreakpoints.breakpoints.up('lg')]: {
          maxWidth: '1200px',
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-error': {
          color: themeWithBreakpoints.palette.text.secondary,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        '&.Mui-error': {
          lineHeight: '16px',
        },
      },
    },
    MuiTextField: {},
    MuiRadio: {
      root: {
        color: themeWithBreakpoints.palette.primary.main,
      },
    },
    MuiCheckbox: {
      root: {
        color: themeWithBreakpoints.palette.primary.main,
      },
    },
    MuiTableRow: {
      root: {
        '&$selected': {
          backgroundColor: 'unset',
        },
        '&$selected:hover': {
          backgroundColor: 'unset',
        },
      },
      selected: {},
    },
    MuiTableCell: {
      root: {
        paddingTop: '14px',
        paddingBottom: '14px',
        fontSize: '15px',
        lineHeight: '22px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
        userSelect: 'none',
      },
      head: {
        color: 'rgba(0, 0, 0, 0.72)',
        fontSize: '15px',
        fontWeight: themeWithBreakpoints.typography.fontWeightBold,
        lineHeight: '22px',
        borderBottom: 'unset',
      },
    },
  },
});

export default theme;
