import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'regenerator-runtime/runtime';

import 'core-js';
import 'core-js/es';
/* import 'core-js/es/regexp';
import 'core-js/es/function';
import 'core-js/es/parse-int';
import 'core-js/es/parse-float';
import 'core-js/es/number';
import 'core-js/es/math';
import 'core-js/es/string';
import 'core-js/es/date';
import 'core-js/es/array';
import 'core-js/es/map';
import 'core-js/es/weak-map';
import 'core-js/es/set';
import 'core-js/es/reflect'; */
import 'core-js/web';
import { enableES5 } from 'immer';

import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import './index.css';
import './features/translation';
import reportWebVitals from './reportWebVitals';
import BrowserBaseApp from './features/browserBaseApp';

const history = createBrowserHistory();
enableES5();

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <BrowserBaseApp />
    </Router>
  </React.StrictMode>,
  document.querySelector('#root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
